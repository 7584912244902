import BasePage from '@/found/components/table_page';

export default {
  name: 'marketing_activity_detail_list',
  extends: BasePage,
  data() {
    return {
      params: {
        actType: 'project',
      },
    };
  },
  components: {

  },
  async created() {
    await this.getConfigList('marketing_activity_detail_list');
  },
  methods: {

  },
};
